<template>
  <div class="container">
    <div class="header">
      <div class="header_left">
        <span class="task_title">任务管理</span>
        <span class="task_icon">-</span>
        <span class="nav">{{ this.$route.query.id ? '编辑' : '新增' }}</span>
      </div>
      <div class="header_right" @click="goback">
        <img src="../../../../assets/images/back.png" alt="" />
        <span class="goback">返回上一层</span>
      </div>
    </div>
    <div class="content">
      <div class="form">
        <a-form-model :model="addTaskForm" :rules="addTaskFormRules" ref="addTaskFormRef">
          <div class="layout">
            <a-form-model-item label="任务名称" prop="name">
              <a-input placeholder="请输入任务名称" v-model="addTaskForm.name" />
            </a-form-model-item>
            <a-form-model-item label="积分值" prop="integration">
              <a-input placeholder="请输入积分值" v-model="addTaskForm.integration" />
            </a-form-model-item>
          </div>
          <div class="layout">
            <a-form-model-item label="任务类型" prop="type">
              <a-select v-model="addTaskForm.type" placeholder="请选择任务类型">
                <a-select-option v-for="item in branchList" :key="item.id" :value="item.id">
                  {{ item.typeName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="限制名额" prop="seats">
              <a-input placeholder="请输入限制名额" v-model="addTaskForm.seats" />
            </a-form-model-item>
          </div>
          <div class="layout">
            <a-form-model-item label="开始时间" prop="startTime">
              <a-date-picker v-model="addTaskForm.startTime" formate="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" showTime />
            </a-form-model-item>
            <a-form-model-item label="结束时间" prop="endTime">
              <a-date-picker v-model="addTaskForm.endTime" formate="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" showTime />
            </a-form-model-item>
          </div>
          <a-form-model-item label="活动地点" prop="place">
            <a-input placeholder="请输入活动地点" v-model="addTaskForm.place" />
          </a-form-model-item>
          <a-form-model-item prop="detail" label="任务详情">
            <a-textarea placeholder="请输入任务详情" style="height:258px;width:700px" :rows="10" v-model="addTaskForm.detail"> </a-textarea>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="bottom_btn">
        <a-button class="saveadd" @click="save">保存</a-button>
        <a-button class="canceladd" @click="canceladd">取消</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskTypeList, tasksave, taskget, taskupdate } from '@/api/task'
import { checkCourse } from '@/utils/validator'
export default {
  data() {
    return {
      id: 0,
      addTaskForm: {
        name: '',
        integration: undefined,
        type: undefined,
        seats: undefined,
        startTime: '',
        endTime: '',
        detail: ''
      },
      addTaskFormRules: {
        name: [{ required: true, message: '请输入任务名称' }],
        integration: [{ required: true, message: '请输入积分值' }, { validator: checkCourse }],
        type: [{ required: true, message: '请选择任务类型' }],
        seats: [{ required: true, message: '请输入限制名额' }, { validator: checkCourse }],
        startTime: [{ required: true, message: '请选择开始时间' }],
        endTime: [{ required: true, message: '请选择结束时间' }],
        detail: [{ required: true, message: '请输入任务详情' }],
        place: [{ required: true, message: '请输入活动地点' }]
      },
      branchList: []
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getTaskTypeList()
      this.taskget()
    }
    this.getTaskTypeList()
  },
  methods: {
    goback() {
      this.$router.push('/member/task/list')
    },
    async getTaskTypeList() {
      const data = await getTaskTypeList()
      if (data) {
        this.branchList = data.data
        console.log(this.branchList, '111111111111')
      }
    },
    // 新增
    saveadd() {
      this.$refs.addTaskFormRef.validate(async (valid) => {
        if (valid) {
          if (this.addTaskForm.startTime < this.addTaskForm.endTime) {
            const data = await tasksave({
              ...this.addTaskForm
            })
            if (data.code === 200) {
              this.$message.success('新增成功！')
              this.$router.push('/member/task/list')
            }
          } else {
            this.$message.error('开始时间不能大于结束时间')
          }
        }
      })
    },
    // 编辑单体查询
    async taskget() {
      const data = await taskget({
        id: this.id
      })
      this.addTaskForm = data.data
      console.log(data, 'data')
    },
    // 编辑
    edit() {
      this.$refs.addTaskFormRef.validate(async (valid) => {
        if (valid) {
          if (this.addTaskForm.startTime < this.addTaskForm.endTime) {
            const data = await taskupdate({
              ...this.addTaskForm
            })
            if (data.code === 200) {
              this.$message.success('编辑成功!')
              this.$router.push('/member/task/list')
            }
          } else {
            this.$message.error('开始时间不能大于结束时间')
          }
        }
      })
    },
    save() {
      if (this.$route.query.id) {
        this.edit()
      } else {
        this.saveadd()
      }
    },
    // 取消
    canceladd() {
      this.$router.push('/member/task/list')
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  padding: 0 0 20px 0;
}

.header_left {
  width: 112px;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.task_title,
.task_icon {
  color: #888888;
}

.header_right {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav {
  color: #333333;
}

.goback {
  color: #f44600;
  margin-right: 6px;
  padding: 1px 0 0 0;
}

.content {
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 50px;
  height: calc(100vh - 140px);
}

>>> .ant-form-item {
  display: flex;
}

/* >>>.ant-form-item-control {
    width: 340px;
   */
.ant-form-item >>> .ant-input {
  width: 340px;
  height: 36px;
}

>>> .ant-select-selection__rendered {
  width: 340px;
  height: 36px;
}

>>> .ant-select-selection--single {
  width: 340px;
  height: 36px;
}

.form {
  width: 700px;
  display: flex;
}

.layout {
  width: 920px;
  display: flex;
  justify-content: space-between;
}

.saveadd {
  width: 88px;
  height: 36px;
  background: #f44600;
  border-radius: 2px;
  color: #ffffff;
  margin-right: 30px;
}

.canceladd {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #d9dbde;
  color: #666666;
}

.bottom_btn {
  margin: 20px 67px;
}
</style>
